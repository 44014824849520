<template>
  <Form
    @submit="hourlySubmit"
    class="form"
    :validation-schema="hourlySchema"
    v-slot="{ errors, isSubmitting }"
  >
    <span v-if="isSubmitting">
      {{ errorFill(errors) }}
    </span>

    <div
      v-if="!utils.isEmpty(errorOnForm) && isShowModal"
      @click="closeModal"
      class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
    >
      <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
        <div class="flex justify-end p-4">
          <button
            @click="closeModal"
            aria-label="close"
            class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
            type="button"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div class="p-6 pt-0">
          <p v-for="error in errors" :key="error">{{ error }}</p>
        </div>
        <div class="border-t border-gray-600 p-6">
          <button
            @click="closeModal"
            type="button"
            class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
          >
            Ok
          </button>
        </div>
      </div>
    </div>

    <div :class="['home_form home_form-2', 'active']">
      <Field :name="fields.pickup.name" v-slot="{ field }" v-model="orderData.pickup">
        <vue-google-autocomplete
          @placechanged="getAddressData"
          @focus="focusInput($event, 'pickup')"
          @beforeinput="beforeInput($event, 'pickup')"
          classname="input text-sm md:text-lg searchAutocomplete m-0"
          :class="errors.pickup ? 'error' : ''"
          v-bind="field"
          :autoCompleteString="orderData.pickup"
          :placeholder="fields.pickup.placeholder"
          types=""
          id="home_form_2_pickup"
          maxlength="230"
        >
        </vue-google-autocomplete>
      </Field>
      <Field
        as="select"
        class="input m-0 text-sm !text-[#878787] dark:text-white/50 md:text-lg"
        :class="errors.hours ? 'error' : ''"
        name="hours"
        v-model="hourly"
      >
        <option
          v-for="value in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="value"
          :value="value"
          :selected="value === 2"
        >
          {{ value }} hours
        </option>
      </Field>
      <Field name="date_start" v-slot="{ field }" v-model="orderData.date_start">
        <VueDatePicker
          ref="datePickerRef"
          class="input m-0 text-sm md:text-lg"
          auto-apply
          partial-flow
          :teleport-center="ssid"
          placeholder="Date / Time*"
          :class="errors.date_start ? 'error' : ''"
          :preview-format="dateFormat"
          :clearable="false"
          :format="dateFormat"
          :dark="mode === 'dark'"
          :light="mode === 'light'"
          v-bind="field"
          v-model="datePicker"
          @internal-model-change="handleInternal"
          :min-date="minDate"
          :min-time="minTime"
        >
          <template #right-sidebar>
            <div v-if="datePicker" class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
              <div
                v-for="time in timeOptions"
                :key="time"
                @click="setTime(time)"
                :class="[
                  'time-option cursor-pointer',
                  time === selectedTime ? 'time-selected' : ''
                ]"
              >
                {{ time }}
              </div>
            </div>
          </template>
        </VueDatePicker>
      </Field>
    </div>
    <div class="next_step_button_wrapper mt-0">
      <button type="submit" class="next_step_button" :disabled="isSubmitting">Next</button>
    </div>
  </Form>
</template>

<script setup>
import { ref, inject, onMounted, watch } from 'vue'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useRouter } from 'vue-router'
import moment from 'moment'
import * as yup from 'yup'
import { Form, Field } from 'vee-validate'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
import { useGoogleAddress } from '@/compose/googleAddress'
import { useDatePicker } from '@/compose/datePicker'
import { useOrderStore } from '@/stores/order'
import { useCentrifugoStore } from '@/stores/centrifugo'

const utils = inject('utils')
const router = useRouter()

const regexLink = inject('regexLink')
const regexIsHttps = inject('regexIsHttps')

const mainStore = useMainStore()
const orderStore = useOrderStore()
const centrifugoStore = useCentrifugoStore()

const { mode, flow, ssid, isRequesting } = storeToRefs(mainStore)
const { orderData, orderId } = storeToRefs(orderStore)

let hourly = ref(orderData.value.hours ? orderData.value.hours : 2)

const { fields, beforeInput, focusInput, getAddressData } = useGoogleAddress('hourlyAsDirected')

const {
  datePickerRef,
  minDate,
  minTime,
  datePicker,
  handleInternal,
  dateFormat,
  initialDate,
  timeSetFirstTime,
  timeOptions
} = useDatePicker()

const selectedTime = ref('')
const isShowModal = ref(true)
const errorOnForm = ref(null)
const errorFill = (errorBag) => {
  errorOnForm.value = errorBag
}

const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = updatedDate
    orderData.value.date_start = moment(updatedDate).format('YYYY-MM-DD HH:mm')
  }
  selectedTime.value = time

  datePickerRef.value.closeMenu()
}

watch(errorOnForm, (newVal) => {
  if (!utils.isEmpty(newVal)) {
    isShowModal.value = true
  }
})

function closeModal() {
  isShowModal.value = false
}

const hourlySchema = yup.object({
  pickup: yup
    .string()
    .required('Pick up is a required field')
    .max(230, 'Pick up should not exceed 230 characters')
    .test(
      'location-restricted',
      'The airport is located at:  Amerigo Vespucci Airport, Via del Termine, Florence, Metropolitan City of Florence, Italy',
      (value) => {
        return !value.toString().includes('Amerigo Vespucci Airport, Viale Belfiore')
      }
    )
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      (value, ctx) => {
        if (orderData.value.ride_history) return true
        if (orderData.value.fleet) return true
        return fields.value[ctx.path].valid
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  date_start: yup
    .string()
    .required('Date is a required field')
    .max(230, 'Date should not exceed 230 character')
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true })
})

const hourlySubmit = (values) => {
  values.date_start = moment(values.date_start).format('YYYY-MM-DD HH:mm')

  orderStore.update({ ...values, distance: values.hours * 20 })

  orderStore.orderData.allowedPages['contact'] = 1
  if (flow.value === 'mainsite') {
    isRequesting.value = true
    orderStore
      .updateStorage({
        ...values,
        ssid: ssid.value,
        type_of_service: 'hourlyAsDirected',
        status: 1
      })
      .then((response) => {
        console.log(response)
        const socketData = {
          event: 'fill_form',
          cache_id: response.data.data.id
        }
        centrifugoStore.send('dli-' + ssid.value, socketData)
        isRequesting.value = false
      })
  }
  if (flow.value !== 'mainsite') {
    router.push('/contact')
  }
}

onMounted(() => {
  if (orderData.value.date_start) {
    timeSetFirstTime.value = false
    datePicker.value = new Date(orderData.value.date_start)
  }
  if (orderId.value !== null) {
    fields.value.pickup.valid = true
    //orderStore.$reset()
    //mainStore.$reset()
    //datePicker.value = null
  }
  orderStore.update({ status: 1, type_of_service: 'hourlyAsDirected' })
})
</script>
